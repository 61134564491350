import parseTimestamp from "./parseTimestamp";

const getCommonMaxDateFromProducts = (products) =>
  products.reduce((maxDate, product) => {
    if (!product.sidebar) {
      return maxDate;
    }
    if (maxDate === null) {
      return parseTimestamp(product.date_max);
    }
    return new Date(Math.min(maxDate, parseTimestamp(product.date_max)));
  }, null);

export default getCommonMaxDateFromProducts;

import { getJSON, postJSON, postData, putData } from "lib/util";
import { COMPOSITE, SelectionMode } from "./lib/constants";

export const TOGGLE_DARKMODE = "TOGGLE_DARKMODE";
export const TOGGLE_SELECTION_MODE = "TOGGLE_SELECTION_MODE";
export const LAYERS_REQUEST = "LAYERS_REQUEST";
export const SET_ACTIVE_LAYER = "SET_ACTIVE_LAYER";
export const SET_ACTIVE_LAYER_GROUP = "SET_ACTIVE_LAYER_GROUP";

export const SET_MAP_LOADING = "SET_MAP_LOADING";

export const PRODUCTS_REQUEST = "PRODUCTS_REQUEST";
export const PRODUCTS_RECEIVE = "PRODUCTS_RECEIVE";
export const PRODUCTS_TOGGLE = "PRODUCTS_TOGGLE";
export const RESET_ACTIVE_INDICATORS = "RESET_ACTIVE_INDICATORS";
export const OVERLAYS_REQUEST = "OVERLAYS_REQUEST";
export const OVERLAYS_RECEIVE = "OVERLAYS_RECEIVE";
export const OVERLAYS_TOGGLE = "OVERLAYS_TOGGLE";
export const OVERLAYS_OPACITY_UPDATE = "OVERLAYS_OPACITY_UPDATE";

export const REGION_REQUEST = "REGION_REQUEST";
export const REGION_RECEIVE = "REGION_RECEIVE";

export const TOGGLE_VISIBLE_TAB = "TOGGLE_VISIBLE_TAB";

export const TIMESERIES_UPDATE = "TIMESERIES_UPDATE";
export const TIMESERIES_REQUEST = "TIMESERIES_REQUEST";
export const TIMESERIES_RECEIVE = "TIMESERIES_RECEIVE";

export const TIMESERIES_FEATURE_UPDATE = "TIMESERIES_FEATURE_UPDATE";
export const TIMESERIES_FEATURE_REQUEST = "TIMESERIES_FEATURE_REQUEST";
export const TIMESERIES_FEATURE_RECEIVE = "TIMESERIES_FEATURE_RECEIVE";

export const FEATURE_SET_HOVER = "FEATURE_SET_HOVER";
export const TOGGLE_FEATURE = "TOGGLE_FEATURE";
export const UPDATE_FEATURE = "UPDATE_FEATURE";

export const GRAPH_SHOW_TOGGLE = "GRAPH_SHOW_TOGGLE";
export const GRAPH_EXPAND_TOGGLE = "GRAPH_EXPAND_TOGGLE";
export const GRAPH_SET_DATA_MODE = "GRAPH_SET_DATA_MODE";
export const GRAPH_XAXIS_RANGE_SET = "GRAPH_XAXIS_RANGE_SET";

export const DATE_SET = "DATE_SET";

export const ACCOUNT_IS_LOGGED_IN = "ACCOUNT_IS_LOGGED_IN";
export const ACCOUNT_LOGIN_REQUEST = "ACCOUNT_LOGIN_REQUEST";
export const ACCOUNT_LOGIN_RECEIVE = "ACCOUNT_LOGIN_RECEIVE";
export const ACCOUNT_LOGOUT = "ACCOUNT_LOGOUT";
export const ACCOUNT_REGISTER_REQUEST = "ACCOUNT_REGISTER_REQUEST";
export const ACCOUNT_REGISTER_RECEIVE = "ACCOUNT_REGISTER_RECEIVE";
export const ACCOUNT_VERIFY_REQUEST = "ACCOUNT_VERIFY_REQUEST";
export const ACCOUNT_VERIFY_RECEIVE = "ACCOUNT_VERIFY_RECEIVE";

export const ACCOUNT_RESET_PASSWORD_REQUEST = "ACCOUNT_RESET_PASSWORD_REQUEST";
export const ACCOUNT_RESET_PASSWORD_RECEIVE = "ACCOUNT_RESET_PASSWORD_RECEIVE";

export const ACCOUNT_CHANGE_NAME_REQUEST = "ACCOUNT_CHANGE_NAME_REQUEST";
export const ACCOUNT_CHANGE_NAME_RECEIVE = "ACCOUNT_CHANGE_NAME_RECEIVE";

export const ACCOUNT_CHANGE_EMAIL_REQUEST = "ACCOUNT_CHANGE_EMAIL_REQUEST";
export const ACCOUNT_CHANGE_EMAIL_RECEIVE = "ACCOUNT_CHANGE_EMAIL_RECEIVE";

export const CURRENT_ACCOUNT_REQUEST = "CURRENT_ACCOUNT_REQUEST";
export const CURRENT_ACCOUNT_RECEIVE = "CURRENT_ACCOUNT_RECEIVE";

export const ACCOUNT_SEND_REGISTER_EMAIL_REQUEST =
  "ACCOUNT_SEND_REGISTER_EMAIL_REQUEST";
export const ACCOUNT_SEND_REGISTER_EMAIL_RECEIVE =
  "ACCOUNT_SEND_REGISTER_EMAIL_RECEIVE";

export const ACCOUNT_SEND_RESET_PASSWORD_REQUEST =
  "ACCOUNT_SEND_RESET_PASSWORD_REQUEST";
export const ACCOUNT_SEND_RESET_PASSWORD_RECEIVE =
  "ACCOUNT_SEND_RESET_PASSWORD_RECEIVE";

export const USER_DISCLAIMER_AFFIRMATION_RECEIVE =
  "USER_DISCLAIMER_AFFIRMATION_RECEIVE";
export const USER_TUTORIAL_COMPLETION_RECEIVE =
  "USER_TUTORIAL_COMPLETION_RECEIVE";
const API = process.env.GATSBY_API_URL;

export const dateSet = (date) => ({
  type: DATE_SET,
  date,
});

export const toggleDarkMode = (isDarkMode) => ({
  type: TOGGLE_DARKMODE,
  isDarkMode,
});

export const getLayers = (lang_tag) => (dispatch) => {
  dispatch({
    type: LAYERS_REQUEST,
    payload: {
      loading: true,
    },
  });
  getJSON(
    `${API}/layers/`,
    (error, response) => {
      if (!error && response) {
        dispatch({
          type: LAYERS_REQUEST,
          payload: {
            loading: false,
            data: response,
          },
        });
        if (response?.length) {
          dispatch(setActiveLayer(response[0]));
          dispatch(setActiveLayerGroup(response[0].grouping[0]));
        }
      } else {
        dispatch({
          type: LAYERS_REQUEST,
          payload: {
            loading: false,
            error: error,
          },
        });
      }
    },
    lang_tag
  );
};

export const setActiveLayer = (layer, active = null) => ({
  type: SET_ACTIVE_LAYER,
  payload: { layer, active },
});

export const setActiveLayerGroup = (layerGroup, active = null) => ({
  type: SET_ACTIVE_LAYER_GROUP,
  payload: { layerGroup, active },
});

export const setMapLoading = (isMapLoading) => ({
  type: SET_MAP_LOADING,
  payload: { isMapLoading },
});

export const toggleSelectionMode = (selectionMode) => ({
  type: TOGGLE_SELECTION_MODE,
  selectionMode,
});

export const toggleFeature = (feature_id, properties) => (dispatch) => {
  dispatch({
    type: TOGGLE_FEATURE,
    payload: {
      feature_id,
      properties,
    },
  });
  dispatch(timeseriesFeatureUpdate());
};

export const updateFeatureNames = (feature_id, properties) => (dispatch) => {
  dispatch({
    type: UPDATE_FEATURE,
    payload: {
      feature_id,
      properties,
    },
  });
};

export const featureSetHover = (feature_id) => ({
  type: FEATURE_SET_HOVER,
  feature_id,
});

export const productsRequest = (lang_tag) => (dispatch) => {
  getJSON(
    API + "/products.json",
    (error, response) => {
      if (response) {
        dispatch(productsReceive(response.data));
      }
    },
    lang_tag
  );
};

export const productsReceive = (response) => {
  return {
    type: PRODUCTS_RECEIVE,
    payload: response,
  };
};

export const overlaysRequest = () => (dispatch) => {
  getJSON(API + "/overlays.json", (error, response) => {
    if (response) {
      dispatch(overlaysReceive(response.data));
    }
  });
};

export const overlaysReceive = (response) => {
  return {
    type: OVERLAYS_RECEIVE,
    payload: response,
  };
};

// No longer support Region and RegionText schema.
// Likely to be replaced by Organization, so keep
// existing state management to insert config values by language.
// For now hard-code config response since no endpoint.
export const regionRequest = (lang_tag) => (dispatch) => {
  const responseByLangTag = {
    en: {
      unique_region_text: {
        siteTitle: "Geoguard Dashboard",
        siteDescription: "Geoguard Dashboard Monitoring",
      },
      active_language: "en",
      languages: ["en", "fr", "ar"],
    },
    fr: {
      unique_region_text: {
        siteTitle: "Tableau de bord de Geoguard",
        siteDescription: "Surveillance du tableau de bord Geoguard",
      },
      active_language: "fr",
      languages: ["en", "fr", "ar"],
    },
    ar: {
      unique_region_text: {
        siteTitle: "لوحة القيادة Geoguard",
        siteDescription: "مراقبة لوحة القيادة Geoguard",
      },
      active_language: "ar",
      languages: ["en", "fr", "ar"],
    },
  };

  dispatch(regionReceive(responseByLangTag[lang_tag]));
};

export const regionReceive = (response) => {
  return {
    type: REGION_RECEIVE,
    payload: response,
  };
};

export const setOverlayOpacity = (name, opacity) => {
  return {
    type: OVERLAYS_OPACITY_UPDATE,
    payload: { name, opacity },
  };
};

export const resetActiveIndicators = () => ({
  type: RESET_ACTIVE_INDICATORS,
});

export const productsToggle = (name, active = null) => ({
  type: PRODUCTS_TOGGLE,
  payload: { name, active },
});

export const overlaysToggle = (name, active = null) => ({
  type: OVERLAYS_TOGGLE,
  payload: { name, active },
});

export const toggleVisibleTab = (name) => ({
  type: TOGGLE_VISIBLE_TAB,
  name,
});

export const timeseriesRequest = () => ({
  type: TIMESERIES_REQUEST,
  payload: null,
});

export const timeseriesUpdate = () => (dispatch, getState) => {
  var state = getState();
  //if (state.timeseries_loading) {
  // TODO: cancel or queue requests
  //  return;
  //}

  if (state.products_active.length === 0) {
    // dispatch(timeseriesReceive([]));
    return;
  }

  dispatch(timeseriesRequest());

  let date = new Date(state.date);
  date = date.toISOString().split("-");
  date = date[0] + "-" + date[1] + "-01";

  const nonConflictActiveProducts = state.products_active.filter(
    (p) => p.category !== "Conflict"
  );
  const excludeConflictsAsArgs =
    state.selectionMode === SelectionMode.MultiIndicators &&
    state.visible_tab === COMPOSITE;
  // If multi-produt or -region composite, don't
  // include conflicts as part of composite ratio calc.
  // They are "products", but have no % variances to
  // composite and their ymt data plotted/mapped differently
  var args = {
    product__name__in: excludeConflictsAsArgs
      ? nonConflictActiveProducts
      : state.products_active,
    date: date,
  };

  postJSON(API + "/timeseries.json", args, (error, response) => {
    if (response) {
      dispatch(timeseriesReceive(response.data));
    }
  });
};

export const timeseriesReceive = (response) => ({
  type: TIMESERIES_RECEIVE,
  payload: response,
});

export const timeseriesFeatureRequest = () => ({
  type: TIMESERIES_FEATURE_REQUEST,
  payload: null,
});

export const timeseriesFeatureUpdate = () => (dispatch, getState) => {
  var state = getState();

  //if (state.timeseries_feature_loading) {
  // FUTURE: cancel or queue requests
  //  return;
  //}

  if (state.products_active.length === 0) {
    // dispatch(timeseriesReceive([]));
    return;
  }

  const layerActiveFeatures =
    state.features_active[state.layer_active?.name] ?? [];
  if (layerActiveFeatures.length === 0) {
    // dispatch(timeseriesReceive([]));
    return;
  }

  dispatch(timeseriesFeatureRequest());

  var args = {
    product__name__in: state.products_active,
    feature__id__in: layerActiveFeatures,
  };

  postJSON(API + "/timeseries.json", args, (error, response) => {
    if (response) {
      dispatch(timeseriesFeatureReceive(response.data));
    }
  });
};

export const timeseriesFeatureReceive = (response) => ({
  type: TIMESERIES_FEATURE_RECEIVE,
  payload: response,
});

export const graphExpandToggle = (graph_expand = null) => ({
  type: GRAPH_EXPAND_TOGGLE,
  graph_expand,
});

export const graphShowToggle = (graph_show = null) => ({
  type: GRAPH_SHOW_TOGGLE,
  graph_show,
});

export const graphXaxisRangeSet = (range = null) => ({
  type: GRAPH_XAXIS_RANGE_SET,
  range,
});

export const getCurrentUserRequest = (userId) => (dispatch) => {
  getJSON(`${API}/users/${userId}/`, (error, response) => {
    if (!error) {
      dispatch(getCurrentUserReceive(response));
    }
  });
};

export const getCurrentUserReceive = (response) => {
  return {
    type: CURRENT_ACCOUNT_RECEIVE,
    payload: response,
  };
};

export const accountLoginRequest = (payload) => (dispatch) => {
  dispatch({
    type: ACCOUNT_LOGIN_REQUEST,
    email: payload.email,
  });

  postData(API + "/auth/token/", payload, (error, response) => {
    dispatch(
      accountLoginReceive({
        success: !error,
        data: response,
      })
    );
  });
};

export const accountLoginReceive = (response) => {
  return {
    type: ACCOUNT_LOGIN_RECEIVE,
    payload: response,
  };
};

export const accountLogout = () => ({
  type: ACCOUNT_LOGOUT,
});

export const accountRegisterRequest = (payload) => (dispatch) => {
  dispatch({
    type: ACCOUNT_REGISTER_REQUEST,
    email: payload.email,
  });

  postData(API + "/account/register/", payload, (error, response) => {
    dispatch(
      accountRegisterReceive({
        success: !error,
        data: response,
      })
    );
  });
};

export const accountRegisterReceive = (response) => {
  return {
    type: ACCOUNT_REGISTER_RECEIVE,
    payload: response,
  };
};

export const accountVerifyRequest = (payload) => (dispatch) => {
  dispatch({
    type: ACCOUNT_VERIFY_REQUEST,
    time: new Date(),
  });

  postData(
    API + "/account/verify-registration/",
    payload,
    (error, response) => {
      dispatch(
        accountVerifyReceive({
          success: !error,
          data: response,
        })
      );
    }
  );
};

export const accountVerifyReceive = (response) => {
  return {
    type: ACCOUNT_VERIFY_RECEIVE,
    payload: response,
  };
};

export const accountResetPasswordRequest = (payload) => (dispatch) => {
  dispatch({
    type: ACCOUNT_RESET_PASSWORD_REQUEST,
    email: payload.email,
  });

  postData(API + "/account/reset-password/", payload, (error, response) => {
    dispatch(
      accountResetPasswordReceive({
        success: !error,
        data: response,
      })
    );
  });
};

export const accountResetPasswordReceive = (response) => {
  return {
    type: ACCOUNT_RESET_PASSWORD_RECEIVE,
    payload: response,
  };
};

export const accountChangeNameRequest = (payload, userId) => (dispatch) => {
  dispatch({
    type: ACCOUNT_CHANGE_NAME_REQUEST,
    payload: payload,
  });

  putData(`${API}/users/${userId}/`, payload, (error, response) => {
    const errorMessage =
      error === null || error === undefined ? "" : error.message;

    dispatch(
      accountChangeNameReceive({
        success: !error,
        data: response || errorMessage,
      })
    );
  });
};

export const accountChangeNameReceive = (response) => {
  return {
    type: ACCOUNT_CHANGE_NAME_RECEIVE,
    payload: response,
  };
};

export const accountChangeEmailRequest = (payload) => (dispatch) => {
  dispatch({
    type: ACCOUNT_CHANGE_EMAIL_REQUEST,
    payload: payload,
  });

  postData(`${API}/account/verify-email/`, payload, (error, response) => {
    dispatch(
      accountChangeEmailReceive({
        success: !error,
        data: response,
      })
    );
  });
};

export const accountChangeEmailReceive = (response) => {
  return {
    type: ACCOUNT_CHANGE_EMAIL_RECEIVE,
    payload: response,
  };
};

export const accountSendRegisterEmailRequest = (payload) => (dispatch) => {
  dispatch({
    type: ACCOUNT_SEND_REGISTER_EMAIL_REQUEST,
    payload,
  });

  postData(`${API}/account/register-email/`, payload, (error, response) => {
    dispatch(
      accountSendRegisterEmailReceive({
        success: !error,
        data: response,
      })
    );
  });
};

export const accountSendRegisterEmailReceive = (response) => ({
  type: ACCOUNT_SEND_REGISTER_EMAIL_RECEIVE,
  payload: response,
});

export const accountSendResetPasswordRequest = (payload) => (dispatch) => {
  dispatch({
    type: ACCOUNT_SEND_RESET_PASSWORD_REQUEST,
    payload,
  });

  postData(
    API + "/account/send-reset-password-link/",
    payload,
    (error, response) => {
      dispatch(
        accountSendResetPasswordReceive({
          success: !error,
          data: response,
        })
      );
    }
  );
};

export const accountSendResetPasswordReceive = (response) => ({
  type: ACCOUNT_SEND_RESET_PASSWORD_RECEIVE,
  payload: response,
});

export const userDisclaimerAffirmationRequest = (affirm) => (dispatch) => {
  dispatch(userDisclaimerAffirmationReceive(affirm));
};
export const userDisclaimerAffirmationReceive = (affirm) => ({
  type: USER_DISCLAIMER_AFFIRMATION_RECEIVE,
  affirm,
});

export const userTutorialCompletion = (completion) => (dispatch) => {
  dispatch(userTutorialCompletionReceive(completion));
};

export const userTutorialCompletionReceive = (completion) => ({
  type: USER_TUTORIAL_COMPLETION_RECEIVE,
  completion,
});
